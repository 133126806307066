var exports = {};

exports = function (hljs) {
  var API_CLASS = {
    className: "built_in",
    begin: "\\b(AV|CA|CF|CG|CI|CL|CM|CN|CT|MK|MP|MTK|MTL|NS|SCN|SK|UI|WK|XC)\\w+"
  };
  var OBJC_KEYWORDS = {
    keyword: "int float while char export sizeof typedef const struct for union " + "unsigned long volatile static bool mutable if do return goto void " + "enum else break extern asm case short default double register explicit " + "signed typename this switch continue wchar_t inline readonly assign " + "readwrite self @synchronized id typeof " + "nonatomic super unichar IBOutlet IBAction strong weak copy " + "in out inout bycopy byref oneway __strong __weak __block __autoreleasing " + "@private @protected @public @try @property @end @throw @catch @finally " + "@autoreleasepool @synthesize @dynamic @selector @optional @required " + "@encode @package @import @defs @compatibility_alias " + "__bridge __bridge_transfer __bridge_retained __bridge_retain " + "__covariant __contravariant __kindof " + "_Nonnull _Nullable _Null_unspecified " + "__FUNCTION__ __PRETTY_FUNCTION__ __attribute__ " + "getter setter retain unsafe_unretained " + "nonnull nullable null_unspecified null_resettable class instancetype " + "NS_DESIGNATED_INITIALIZER NS_UNAVAILABLE NS_REQUIRES_SUPER " + "NS_RETURNS_INNER_POINTER NS_INLINE NS_AVAILABLE NS_DEPRECATED " + "NS_ENUM NS_OPTIONS NS_SWIFT_UNAVAILABLE " + "NS_ASSUME_NONNULL_BEGIN NS_ASSUME_NONNULL_END " + "NS_REFINED_FOR_SWIFT NS_SWIFT_NAME NS_SWIFT_NOTHROW " + "NS_DURING NS_HANDLER NS_ENDHANDLER NS_VALUERETURN NS_VOIDRETURN",
    literal: "false true FALSE TRUE nil YES NO NULL",
    built_in: "BOOL dispatch_once_t dispatch_queue_t dispatch_sync dispatch_async dispatch_once"
  };
  var LEXEMES = /[a-zA-Z@][a-zA-Z0-9_]*/;
  var CLASS_KEYWORDS = "@interface @class @protocol @implementation";
  return {
    aliases: ["mm", "objc", "obj-c"],
    keywords: OBJC_KEYWORDS,
    lexemes: LEXEMES,
    illegal: "</",
    contains: [API_CLASS, hljs.C_LINE_COMMENT_MODE, hljs.C_BLOCK_COMMENT_MODE, hljs.C_NUMBER_MODE, hljs.QUOTE_STRING_MODE, {
      className: "string",
      variants: [{
        begin: "@\"",
        end: "\"",
        illegal: "\\n",
        contains: [hljs.BACKSLASH_ESCAPE]
      }, {
        begin: "'",
        end: "[^\\\\]'",
        illegal: "[^\\\\][^']"
      }]
    }, {
      className: "meta",
      begin: "#",
      end: "$",
      contains: [{
        className: "meta-string",
        variants: [{
          begin: "\"",
          end: "\""
        }, {
          begin: "<",
          end: ">"
        }]
      }]
    }, {
      className: "class",
      begin: "(" + CLASS_KEYWORDS.split(" ").join("|") + ")\\b",
      end: "({|$)",
      excludeEnd: true,
      keywords: CLASS_KEYWORDS,
      lexemes: LEXEMES,
      contains: [hljs.UNDERSCORE_TITLE_MODE]
    }, {
      begin: "\\." + hljs.UNDERSCORE_IDENT_RE,
      relevance: 0
    }]
  };
};

export default exports;